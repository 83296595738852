@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap);
body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiListItemText-secondary span {
  display: block;
}
p {
  margin: 0;
}

/* A hack to hide the nav menu (a hamburger) because it's not needed when there's only one resource (items) */
/* Comment out to show the nav menu, also change values of sidebar in the theme  */
header div.MuiToolbar-root > button:first-of-type {
  display: none;
}
.MuiBackdrop-root {
  opacity: 0;
}
.MuiBackdrop-root .MuiDrawer-paper {
  display: none;
}

/* Flatten the form */
.edit-page .MuiPaper-root,
.create-page .MuiPaper-root {
  background-color: transparent;
  box-shadow: 0 0 0 0 #ccc;
}

/* Format "Click to select an image" */
/* .ra-input-image p {
  text-align: left;
  color: darkgrey;
} */
/* A hack to hide the refresh button */
header.MuiPaper-root button[aria-label="Refresh"] {
  display: none;
}

header .MuiToolbar-root {
  padding-right: 0;
}
/* Section label */
span.sectionLabel {
  position: absolute;
  top: 8px;
  left: 108px;
  letter-spacing: 1px;
  padding: 0 3px;
  font-size: 11px;
  text-transform: uppercase;
  color: hsla(0, 0%, 10%, 1);
}
.statuses {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

/* Toolbar */
div.list-page div.MuiToolbar-root {
  padding-left: 0;
}
.MuiToolbar-root form {
  margin-top: 3px;
  min-height: 0;
}

/* List */
ul.MuiList-padding {
  padding-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.MuiDrawer-docked {
  margin-top: 1rem;
}

div .menuActive,
div .menuActive.MuiListItem-button:hover {
  background-color: lightgrey;
  color: rgba(0, 0, 0, 0.87);
}

div .MuiListItem-gutters {
  padding-left: 8px;
}

a .MuiListItemIcon-root {
  min-width: 34px;
}

/* SwipeableListItem */

.swipe-left {
  background-color: darkblue;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 1rem;
  color: whitesmoke;
}

.swipe-right {
  background-color: green;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 1rem;
  color: whitesmoke;
}

/* Ribbon */

/* common */
.ribbon {
  width: 126px;
  height: 90px;
  overflow: hidden;
  position: absolute;
  opacity: 0.8;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 0;
}
.ribbon.sold {
  height: 90px;
}

.ribbon.sold span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 3px 0;
  background-color: darkblue;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 10px/1 "Roboto", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  left: -76px;
  top: 19px;
}

.ribbon.reduced span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 3px 0;
  background-color: green;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 10px/1 "Roboto", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: 0;
  left: -1px;
}

.ribbon-top-left.sold {
  top: 0;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  -webkit-transform: rotate(-42deg);
          transform: rotate(-42deg);
}
/* Tabs */

.MuiTabs-root .MuiTab-root {
  padding-left: 0;
  padding-right: 0;
  text-transform: none;
}
.MuiTab-wrapper {
  font-size: 1rem;
}

/* compensate for a shorter or longer string of text here  */
#simple-tab-0,
#simple-tab-1 {
  min-width: 27%;
}
#simple-tab-2,
#simple-tab-3 {
  min-width: 23%;
}

button.MuiTab-textColorPrimary {
  color: rgba(0, 0, 0, 0.74);
}
/*
#indicator {
  a span element underlining the selected tab
} */

/* Chips */

.chip .MuiChip-root {
  font-size: 11px;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.chip .MuiChip-sizeSmall {
  height: 15px;
}
.chip .MuiChip-labelSmall {
  padding-left: 6px;
  padding-right: 5px;
}

#simple-tab-0 .MuiChip-labelSmall {
  padding-left: 6px;
  padding-right: 6px;
}

#simple-tab-0 .MuiChip-root,
#simple-tab-3 .MuiChip-root {
  font-size: 13px;
  height: 19px;
  /* width: 18px; */
}
button.Mui-selected span.MuiChip-label {
  color: #111;
}

/* #simple-tab-0 .MuiChip-root {
  background-color: #e49329;
  color: #fff;
} */

/* TableGrid (large screen) */

tr .column-createdate,
tr .created {
  padding-right: 0;
  text-align: right;
}

/* Login page */

#username,
#password {
  font-size: 0.9rem;
}

/* Create Form */

#create-form,
#edit-form {
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 5px;
  max-width: 550px;
}
#edit-form {
  margin-top: -4px;
}

.MuiFormHelperText-root {
  display: none;
}

/* Select image box */
#itemImageDiv {
  margin-top: 36px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 110px;
}

/* select image button */
#itemImageDiv p {
  margin: -6px 2px 0 2px;
  background-color: #ebebeb;
  font-size: 12px;
  padding: 1px 0;
  border-radius: 3px;
  color: #444;
}
#itemImageDiv p:hover {
  color: #333;
  background: #e8e8e8;
}

#itemImageDiv label {
  margin-top: -16px;
  background-color: #fafafa;
  width: 50px;
  left: 8px;
  padding-left: 5px;
}
#itemImageDiv img {
  object-fit: cover;
  border-radius: 4px;
  margin: 0 10px;
}

#itemImageDiv,
#itemNameDiv,
#itemExpiryAndSectionDiv,
#itemNoteDiv,
#itemSoldAndReducedDiv {
  margin-bottom: 19px;
}

#itemSoldAndReducedDiv span {
  font-size: 13px;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #fafafa;
  padding: 0 5px;
}

@media only screen and (max-device-width: 370px) {
  .MuiInputBase-root .MuiOutlinedInput-input {
    padding: 10px 3px;
  }
}

div .MuiSelect-select:focus {
  background-color: #fafafa;
}

/* input name */
#name {
  font-size: 1.2rem;
  height: 20px !important;
}

label span {
  font-size: 18px;
}

/* Temp placement */

.status {
  margin-left: 3px;
}
.status.created {
  margin-left: 0;
}

#itemEditDeleteButton {
  padding: 0;
}

/* Revert colors */

div.MuiBox-root div.MuiTabs-root > div {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.11); */
}
#main-content {
  background-color: #f4f4f4;
}

#main-content .edit-page,
#main-content .create-page {
  background-color: white;
}

div.list-page div.MuiToolbar-root {
  background-color: #f4f4f4;
}

header.MuiPaper-root div.MuiToolbar-root {
  background-color: white;
}

ul.MuiList-root {
  padding-bottom: 0;
}

#ExpiryTracker {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  margin-left: 4px;
}

